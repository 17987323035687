import React from 'react';

const escapeHTML = string =>
  string
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

export default ({ headline, description, url, ogimage, date, updated }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: `
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://polypane.app${url}"
      },
      "headline": "${headline}",
      "image": [
        "https://polypane.app/og-images/${ogimage}.png"
       ],
      "datePublished": "${date}",
      "dateModified": "${updated}",
      "author": {
        "@type": "Person",
        "name": "Kilian Valkhof"
      },
       "publisher": {
        "@type": "Organization",
        "name": "Polypane",
        "logo": {
          "@type": "ImageObject",
          "url": "https://polypane.app/general/logo.png"
        }
      },
      "description": "${escapeHTML(description)}"
    }
  `,
    }}
  />
);
