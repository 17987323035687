import { css } from 'astroturf';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import CTA from '../components/CTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import TableOfContents from '../components/TableOfContents';
import JsonLD from '../components/jsonld-post';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .arrow {
    vertical-align: text-bottom;
    transform: translateY(-2px);
  }

  .pt {
    max-width: 40rem;
    margin: 0 auto 6rem;
  }
  .date {
    display: block;
    margin: 0 0 0.5rem;
  }
`;
export default class PostTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.mdx;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <SEO
          title={post.title}
          keywords={['']}
          description={postNode.excerpt}
          pathname={slug}
          ogFileName={postNode.fields.ogFileName}
          meta={[
            {
              name: `twitter:label1`,
              content: 'From the Polypane blog',
            },
            {
              property: `twitter:data1`,
              content: 'Try Polypane for free',
            },
            {
              property: `twitter:label2`,
              content: 'Est. reading time',
            },
            {
              property: `twitter:data2`,
              content: `${postNode.timeToRead} minutes`,
            },
            {
              property: `article:published_time`,
              content: post.date,
            },
            post.updated
              ? {
                  property: `article:modified_time`,
                  content: post.updated,
                }
              : {},
          ]}
        />
        <PageHeader>
          {post.cover ? <img className="cover-image" src={post.cover} alt="" /> : null}
          <h1>{post.title}</h1>
          <span className={styles.date}>
            {postNode.timeToRead} min read. <em>{post.updated ? <>First posted</> : <>Posted</>} on</em>{' '}
            <time itemProp="datePublished" dateTime={post.date}>
              {new Date(post.date).toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </time>
            .{' '}
            {post.updated ? (
              <>
                <br />
                <em>Last updated:</em>{' '}
                <time itemProp="dateModified" dateTime={post.updated}>
                  {new Date(post.updated).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </time>
                .
              </>
            ) : null}
          </span>
          <h2>
            From the <Link to="/blog/">Polypane blog</Link>
          </h2>
        </PageHeader>
        <article className="widePage">
          {postNode.tableOfContents?.items && <TableOfContents items={postNode.tableOfContents.items} />}
          <div className="childPadding" id="article">
            <MDXRenderer>{postNode.body}</MDXRenderer>
          </div>
        </article>

        <JsonLD
          headline={post.title}
          description={postNode.excerpt}
          url={slug}
          ogimage={postNode.fields.ogFileName}
          date={post.date}
          updated={post.updated}
        />
        <CTA />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      tableOfContents
      excerpt(pruneLength: 160)
      frontmatter {
        title
        cover
        date
        updated
      }
      fields {
        nextTitle
        nextSlug
        prevTitle
        prevSlug
        slug
        date
        ogFileName
      }
    }
  }
`;
